import { isLoggedIn } from '../../uiComponents/login';
import logout from '../../uiComponents/logout';
import NoticePopup from '../../models/uiPopups/notice';

export function getCookie (name) {
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));

  if (name === 'token_check' && isLoggedIn && match === null) {
    logout();
    new NoticePopup('Your session has expired, please sign in again.'); // eslint-disable-line
  }
  return match ? match[1] : null;
}
