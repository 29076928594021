import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Mesh, Box3, Vector3, MeshBasicMaterial, BoxGeometry, Color, BufferGeometry, Float32BufferAttribute, MultiplyBlending } from 'three';
import { Loader } from './Loader';
import { DynamicTexture } from '../threex.dynamictexture';
import { config } from '../config/config.js';
// import { OBB } from 'three/examples/jsm/math/OBB.js';

export class ModelLoader {
  static loader = new GLTFLoader();
  static preloader = new Loader();

  constructor (addTo, renderer) {
    this.addTo = addTo;
    this.renderer = renderer;
  }

  setAddTo = (addTo) => {
    this.addTo = addTo;
  }

  loadObj = (model, objectIndex, selectObjectIndex, positionTest = false, bottomColision = false, topColision = false, color = '#ffffff' /* texture */) => {
    // const material = new TextureLoader().load(texture);
    ModelLoader.preloader.show();
    ModelLoader.loader.load(
      model,
      (gltf) => {
        process.env.ENV === 'dev' && console.log('model loaded', gltf.scene);
        gltf.scene.traverse((child) => {
          if (child.material && child.material.name === 'custom_color') {
            child.material.color = new Color(color);
          }
          if (child.name === 'root-object') {
            const box = new Box3().setFromObject(child);
            const size = new Vector3();
            box.getSize(size);
            // child.userData.obb = new OBB();
            // child.userData.obb.halfSize.copy(size).multiplyScalar(0.5);
            const hitBox = new Mesh(new BoxGeometry(size.x, size.y, size.z), new MeshBasicMaterial({ color: 0xffff00, wireframe: true }));
            const translateAmount = positionTest && child.userData.upperLimit ? (child.userData.upperLimit - size.y / 2) : size.y / 2;
            hitBox.position.setY(translateAmount);
            hitBox.name = 'hitBox';
            hitBox.visible = false;
            child.add(hitBox);
            child.size = {
              x: size.x,
              z: size.z
            };
            child.objectIndex = objectIndex;
            child.selectObjectIndex = selectObjectIndex;
            child.positionParam = positionTest || {};
            child.rotationNumber = 0;
            child.wallElement = false;
            child.bottomColision = bottomColision;
            child.topColision = topColision;
            this.addTo.add(child);

            if (positionTest) {
              hitBox.position.setZ(size.z / 2);
              child.wallElement = true;
              child.position.setX(positionTest.x);
              child.position.setZ(positionTest.z);
              child.setRotationFromAxisAngle(positionTest.rotationAxis, positionTest.angle);
              child.index = positionTest.wallIndex;
              const normalVector = new Vector3(0, 0, -1);
              normalVector.applyAxisAngle(positionTest.rotationAxis, positionTest.angle);
              child.normal = normalVector;

              const dimensionLineMaterial = new MeshBasicMaterial({ color: config.distanceLineColor });
              const dimensionLineHeightHalf = 0.01;
              const distanceComponentWidth = 0.5;
              const distanceComponentHeightHalf = distanceComponentWidth / 4;

              const leftDistanceGeom = new BufferGeometry().setAttribute(
                'position',
                new Float32BufferAttribute(
                  [
                    -size.x / 2 - distanceComponentWidth, translateAmount - distanceComponentHeightHalf, 0.01,
                    -size.x / 2, translateAmount - distanceComponentHeightHalf, 0.01,
                    -size.x / 2, translateAmount + distanceComponentHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth, translateAmount - distanceComponentHeightHalf, 0.01,
                    -size.x / 2, translateAmount + distanceComponentHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth, translateAmount + distanceComponentHeightHalf, 0.01
                  ],
                  3
                )
              );

              leftDistanceGeom.setAttribute(
                'uv',
                new Float32BufferAttribute(
                  [
                    0, 0,
                    1, 0,
                    1, 1,
                    0, 0,
                    1, 1,
                    0, 1
                  ],
                  2
                )
              );

              const leftDistance = new Mesh(leftDistanceGeom, new MeshBasicMaterial({ blending: MultiplyBlending }));
              leftDistance.name = 'leftDistance';
              leftDistance.visible = false;
              const tempLeftDynamicTexture = new DynamicTexture(512, 128);
              tempLeftDynamicTexture.context.font = '120px Verdana';
              tempLeftDynamicTexture.texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
              leftDistance.dynamicTexture = tempLeftDynamicTexture;

              const leftLineGeom = new BufferGeometry().setAttribute(
                'position',
                new Float32BufferAttribute(
                  [
                    -size.x / 2 - distanceComponentWidth * 2, translateAmount - dimensionLineHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth, translateAmount - dimensionLineHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth, translateAmount + dimensionLineHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth * 2, translateAmount - dimensionLineHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth, translateAmount + dimensionLineHeightHalf, 0.01,
                    -size.x / 2 - distanceComponentWidth * 2, translateAmount + dimensionLineHeightHalf, 0.01
                  ],
                  3
                )
              );
              const leftLine = new Mesh(leftLineGeom, dimensionLineMaterial);
              leftDistance.add(leftLine);

              leftDistance.dynamicTexture.clear('#ffffff');
              leftDistance.material.map = leftDistance.dynamicTexture.texture;
              child.add(leftDistance);

              const rightDistanceGeom = new BufferGeometry().setAttribute(
                'position',
                new Float32BufferAttribute(
                  [
                    size.x / 2, translateAmount - distanceComponentHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth, translateAmount - distanceComponentHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth, translateAmount + distanceComponentHeightHalf, 0.01,
                    size.x / 2, translateAmount - distanceComponentHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth, translateAmount + distanceComponentHeightHalf, 0.01,
                    size.x / 2, translateAmount + distanceComponentHeightHalf, 0.01
                  ],
                  3
                )
              );

              rightDistanceGeom.setAttribute(
                'uv',
                new Float32BufferAttribute(
                  [
                    0, 0,
                    1, 0,
                    1, 1,
                    0, 0,
                    1, 1,
                    0, 1
                  ],
                  2
                )
              );

              const rightDistance = new Mesh(rightDistanceGeom, new MeshBasicMaterial({ blending: MultiplyBlending }));
              const tempRightDynamicTexture = new DynamicTexture(512, 128);
              tempRightDynamicTexture.context.font = '120px Verdana';
              tempRightDynamicTexture.texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
              rightDistance.dynamicTexture = tempRightDynamicTexture;

              const rightLineGeom = new BufferGeometry().setAttribute(
                'position',
                new Float32BufferAttribute(
                  [
                    size.x / 2 + distanceComponentWidth, translateAmount - dimensionLineHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth * 2, translateAmount - dimensionLineHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth * 2, translateAmount + dimensionLineHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth, translateAmount - dimensionLineHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth * 2, translateAmount + dimensionLineHeightHalf, 0.01,
                    size.x / 2 + distanceComponentWidth, translateAmount + dimensionLineHeightHalf, 0.01
                  ],
                  3
                )
              );
              const rightLine = new Mesh(rightLineGeom, dimensionLineMaterial);
              rightDistance.add(rightLine);

              rightDistance.dynamicTexture.clear('#ffffff');
              rightDistance.material.map = rightDistance.dynamicTexture.texture;
              rightDistance.name = 'rightDistance';
              rightDistance.visible = false;
              child.add(rightDistance);

              const onBeforeRender = (renderer, scene, camera, geometry, material, group) => {
                const testV = new Vector3();
                child.getWorldPosition(testV);
                if (testV.subVectors(camera.position, testV).dot(child.normal) > 0) {
                  geometry.setDrawRange(0, 0);
                }
              };
              const onAfterRender = (renderer, scene, camera, geometry, material, group) => {
                geometry.setDrawRange(0, Infinity);
              };

              child.traverse(childMesh => {
                childMesh instanceof Mesh && (childMesh.onBeforeRender = onBeforeRender);
                childMesh instanceof Mesh && (childMesh.onAfterRender = onAfterRender);
              });
            }
          }
        });
        ModelLoader.preloader.hide();
      },
      undefined,
      function (error) {
        console.error(error);
        ModelLoader.preloader.hide();
      }
    );
  };
}
