import { createElement } from '../../../assets/helpers/common';
import closeSvg from '../../../assets/images/icons/close.svg';
import addSvg from '../../../assets/images/icons/add.svg';
import touchSvg from '../../../assets/images/icons/touch_icon.svg';
import removeSvg from '../../../assets/images/icons/delete.svg';
import { Panels } from '../Panels';
import ColorControlPanel from './ColorControlPanel';
import { Color, MathUtils, Vector3 } from 'three';

export class AddObjects extends Panels {
  constructor (prefix, objects, loadObject, positionOnWall, adjustableAngle = false, wallColors = false) {
    super();
    this.prefix = prefix;
    this.objects = objects;
    this.loadObject = loadObject;
    this.positionOnWall = positionOnWall;
    this.adjustableAngle = adjustableAngle;
    this.wallColors = wallColors;
    this.editObject = null;
    this.container = createElement('div', `${this.prefix}AddObjectsContainer`, 'addObjectsContainer');
    this.modalWrapper = createElement('div', `${this.prefix}AddObjectsModalWrapper`, 'addObjectsModalWrapper');
    this.modalWrapper.onclick = this.hideModal;
    const modal = createElement('div', `${this.prefix}AddObjectsModal`, 'addObjectsModal');

    document.body.appendChild(this.container);
    this.container.appendChild(this.createModelControls());
    this.container.appendChild(this.createDefaultControls());

    this.container.appendChild(this.modalWrapper);
    this.modalWrapper.appendChild(modal);
    modal.appendChild(this.createModalHeader());
    modal.appendChild(this.createTypeSelectWrapper());
    modal.appendChild(this.createObjectSelectionWrapper());
    document.getElementById(`${this.prefix}ObjectTypeSelect${this.objects[0].title}`).classList.add('selectedObjectType');
    document.getElementById(`${this.prefix}AddObjectSelectionTab${this.objects[0].title}`).classList.remove('hiddenClass');
  }

  createModelControls = () => {
    const modelControlsWrapper = createElement('div', `${this.prefix}ModelControls`, 'modelControlsWrapper');
    modelControlsWrapper.classList.add('hiddenClass');
    const modelControlsRemove = createElement('div', `${this.prefix}RemoveObject`, 'modelControlsRemove');
    const removeImg = createElement('img');
    removeImg.src = removeSvg;
    const modelControlsColors = createElement('div', `${this.prefix}ColorPicker`, 'modelControlsColorPicker');
    modelControlsColors.innerHTML = '<p class="addObjectsColorPickerText">Object color</p>';
    this.colorPicker = new ColorControlPanel(this.setObjectColor, modelControlsColors, this.prefix);
    this.colorPicker.create(`${this.prefix}ColorPicker`);

    modelControlsRemove.onclick = this.removeObject;

    modelControlsWrapper.appendChild(modelControlsRemove);
    modelControlsRemove.appendChild(removeImg);
    modelControlsWrapper.appendChild(this.createAngleControls());
    modelControlsWrapper.appendChild(modelControlsColors);
    return modelControlsWrapper;
  }

  createAngleControls = () => {
    const modelControlsAngle = createElement('div', `${this.prefix}AngleObject`, 'containerAngle');
    const angleRangeSlider = createElement('div', `${this.prefix}AngleObjectRangeSlider`, 'range-sliderAngle');
    const angleRangeSliderSpan = createElement('span', `${this.prefix}rs-bullet`, 'rs-labelAngle');
    angleRangeSliderSpan.innerHTML = '0°';
    const angleInput = document.createElement('INPUT');
    angleInput.id = `${this.prefix}rs-range-line`;
    angleInput.className = 'rs-rangeAngle';
    angleInput.setAttribute('type', 'range');
    angleInput.setAttribute('value', 0);
    angleInput.setAttribute('min', -180);
    angleInput.setAttribute('max', 180);
    const modelControlsAngleMinMax = createElement('div', `${this.prefix}AngleObjectMinMax`, 'box-minmaxAngle');
    for (let i = 0; i < 5; i++) {
      modelControlsAngleMinMax.appendChild(createElement('div', `${this.prefix}SliderAngleNotch${i}`, 'sliderAngleNotch'));
    }

    const sliderAngleLabelWrapper = createElement('div', `${this.prefix}SliderAngleLabelWrapper`, 'sliderAngleLabelWrapper');
    const SliderAngleLabelMin = createElement('div', `${this.prefix}SliderAngleLabelMin`, 'sliderAngleLabel');
    const SliderAngleLabelMax = createElement('div', `${this.prefix}SliderAngleLabelMax`, 'sliderAngleLabel');
    SliderAngleLabelMin.innerHTML = '-180°';
    SliderAngleLabelMax.innerHTML = '180°';

    modelControlsAngle.appendChild(angleRangeSlider);
    angleRangeSlider.appendChild(angleRangeSliderSpan);
    angleRangeSlider.appendChild(angleInput);
    modelControlsAngle.appendChild(modelControlsAngleMinMax);
    modelControlsAngleMinMax.appendChild(sliderAngleLabelWrapper);
    sliderAngleLabelWrapper.appendChild(SliderAngleLabelMin);
    sliderAngleLabelWrapper.appendChild(SliderAngleLabelMax);

    angleInput.addEventListener('input', this.onSliderValueChange, false);

    return modelControlsAngle;
  }

  onSliderValueChange = () => {
    const value = document.getElementById(`${this.prefix}rs-range-line`).value;
    this.setSliderValue(value);
    this.editObject.setRotationFromAxisAngle(new Vector3(0, 1, 0), MathUtils.degToRad(value));
    this.editObject.rotationNumber = value;
  }

  setSliderValue = (value) => {
    if (!this.adjustableAngle) return;
    const angleInput = document.getElementById(`${this.prefix}rs-range-line`);
    angleInput.value = value;
    const angleRangeSliderSpan = document.getElementById(`${this.prefix}rs-bullet`);
    angleRangeSliderSpan.innerHTML = `${value}°`;
    const bulletPosition = ((value / angleInput.max) + 1) / 2;
    const bulletOffset = -2 + 54 * bulletPosition;
    angleRangeSliderSpan.style.left = `calc(100% * ${bulletPosition} - ${bulletOffset}px)`;
  }

  createDefaultControls = () => {
    const defaultControlsContainer = createElement('div', `${this.prefix}DefaultControlsContainer`, 'defaultControlsContainer');
    defaultControlsContainer.appendChild(this.createOpenModalBtn());
    this.wallColors && defaultControlsContainer.appendChild(this.createWallColorPicker());

    return defaultControlsContainer;
  }

  createOpenModalBtn = () => {
    const openBtnWrapper = createElement('div', `${this.prefix}OpenAddObjectsBtnWrapper`, 'openAddObjectsBtnWrapper');
    const openModalBtn = createElement('div', `${this.prefix}OpenObjectsModalBtn`, 'openObjectsModalBtn');
    const openImg = createElement('img');
    openImg.src = addSvg;

    openBtnWrapper.appendChild(openModalBtn);
    openModalBtn.appendChild(openImg);
    openModalBtn.onclick = this.showModal;

    return openBtnWrapper;
  }

  createWallColorPicker = () => {
    const wallControlsColors = createElement('div', `${this.prefix}SingleWallColorPicker`, 'modelControlsWallColorPicker');
    wallControlsColors.innerHTML = '<p class="addObjectsColorPickerText">Wall color</p>';
    this.wallColorPicker = new ColorControlPanel(this.wallColors, wallControlsColors, `${this.prefix}Single`);
    this.wallColorPicker.create(`${this.prefix}SingleWallColorPicker`);
    this.wallColorPicker.changeColorPalette(['#ffffff', '#f5f7f2', '#efede2', '#e4e1d8', '#e7e5e0', '#dad4c5', '#b9bbb7', '#dcd3bd', '#c2d2ca', '#f6e2a5', '#b2b8a3', '#d2c3a8', '#e9dad5', '#deb774', '#434b56', '#a4937d', '#686763', '#8b2829', '#524b59', '#4a4b4c', '#000000']);
    this.wallColorPicker.setValue('#e7e5e0');

    return wallControlsColors;
  }

  createModalHeader = () => {
    const modalHeader = createElement('div', `${this.prefix}AddObjectmodalHeader`, 'addObjectmodalHeader');
    const modalDescriptionWrapper = createElement('div', `${this.prefix}AddObjectmodalDescriptionWrapper`, 'addObjectmodalDescriptionWrapper');
    const touchImg = createElement('img');
    touchImg.src = touchSvg;
    const modalDescriptionText = createElement('div', `${this.prefix}AddObjectmodalDescriptionText`, 'addObjectmodalDescriptionText');
    modalDescriptionText.innerHTML = this.localization.strings.selectObject;
    const closeBtn = createElement('div', `${this.prefix}CloseAddObjectsBtn`, 'closeAddObjectsBtn');
    const closeImg = createElement('img');
    closeImg.src = closeSvg;

    modalHeader.appendChild(modalDescriptionWrapper);
    modalDescriptionWrapper.appendChild(touchImg);
    modalDescriptionWrapper.appendChild(modalDescriptionText);
    modalHeader.appendChild(closeBtn);
    closeBtn.appendChild(closeImg);
    closeBtn.onclick = this.hideModal;

    return modalHeader;
  }

  createTypeSelectWrapper = () => {
    const objectTypeSelectWrapper = createElement('div', `${this.prefix}ObjectTypeSelectWrapper`, 'objectTypeSelectWrapper');
    this.objects.forEach(object => {
      objectTypeSelectWrapper.appendChild(this.createTypeSelect(object.title, object.icon));
    });

    return objectTypeSelectWrapper;
  }

  createTypeSelect = (title, icon) => {
    const objectTypeSelect = createElement('div', `${this.prefix}ObjectTypeSelect${title}`, 'objectTypeSelect');
    objectTypeSelect.classList.add(`${this.prefix}ObjectTypeSelect`);
    objectTypeSelect.setAttribute('data-selectionTabId', `${this.prefix}AddObjectSelectionTab${title}`);
    const img = createElement('img', `${this.prefix}ObjectType${title}`, 'objectTypeSelectImg');
    img.src = icon;
    const textDiv = createElement('div', `${this.prefix}ObjectTypeText${title}`, 'objectTypeSelectText');
    textDiv.innerHTML = title;
    const selectPoint = createElement('div', `${this.prefix}SelectPoint${title}`, 'selectPoint');

    objectTypeSelect.appendChild(img);
    objectTypeSelect.appendChild(textDiv);
    objectTypeSelect.appendChild(selectPoint);

    objectTypeSelect.onclick = this.selectType;

    return objectTypeSelect;
  }

  createObjectSelectionWrapper = () => {
    const objectSelectWrapper = createElement('div', `${this.prefix}ObjectSelectWrapperId`, 'objectSelectWrapper');
    this.objects.forEach((object, objectIndex) => {
      objectSelectWrapper.appendChild(this.createObjectSelectionTab(object, objectIndex));
    });

    return objectSelectWrapper;
  }

  createObjectSelectionTab = (object, objectIndex) => {
    const addObjectSelectionTab = createElement('div', `${this.prefix}AddObjectSelectionTab${object.title}`, 'addObjectSelectionTab');
    addObjectSelectionTab.classList.add(`${this.prefix}AddObjectSelectionTab`, 'hiddenClass');
    object.selection.forEach((selectObject, selectObjectIndex) => {
      addObjectSelectionTab.appendChild(this.createSelection(object.title, selectObject, objectIndex, selectObjectIndex));
    });

    return addObjectSelectionTab;
  }

  createSelection = (title, selectObject, objectIndex, selectObjectIndex) => {
    const img = createElement('img', `${this.prefix}AddObject${title}`, 'addObject');
    const imgWrapper = createElement('div', `${this.prefix}imgWrapper${title}`, 'objectImgWrapper');
    img.src = selectObject.image;
    img.setAttribute('data-model', selectObject.model);
    img.setAttribute('data-objectIndex', objectIndex);
    img.setAttribute('data-selectObjectIndex', selectObjectIndex);
    selectObject.wallObject && img.setAttribute('data-isWallModel', '');
    selectObject.bottom_colision && img.setAttribute('data-bottom_colision', '');
    selectObject.top_colision && img.setAttribute('data-top_colision', '');

    img.onclick = this.addObjectToWorld;

    imgWrapper.appendChild(img);
    return imgWrapper;
  }

  selectType = (e) => {
    e.stopPropagation();
    Array.from(document.getElementsByClassName(`${this.prefix}ObjectTypeSelect`)).forEach(selectOption => {
      if (e.currentTarget === selectOption) {
        e.currentTarget.classList.add('selectedObjectType');
      } else {
        selectOption.classList.remove('selectedObjectType');
      }
    });

    const selectionTabId = e.currentTarget.getAttribute('data-selectionTabId');
    Array.from(document.getElementsByClassName(`${this.prefix}AddObjectSelectionTab`)).forEach(objectSelectionTab => {
      if (objectSelectionTab.id === selectionTabId) {
        objectSelectionTab.classList.remove('hiddenClass');
      } else {
        objectSelectionTab.classList.add('hiddenClass');
      }
    });
  }

  setPositionOnWallCallback = (callback) => {
    this.positionOnWall = callback;
  }

  setEditObject = (object) => {
    this.editObject && this.editObject.traverse((child) => {
      if (child.name === 'hitBox') {
        child.visible = false;
      } else if (child.name === 'leftDistance') {
        child.visible = false;
      } else if (child.name === 'rightDistance') {
        child.visible = false;
      }
    });

    this.editObject = object;

    if (object) {
      this.setSliderValue(object.rotationNumber);
      object.traverse((child) => {
        if (child.name === 'hitBox') {
          child.visible = true;
        } else if (child.name === 'leftDistance') {
          child.visible = true;
        } else if (child.name === 'rightDistance') {
          child.visible = true;
        }

        if (child.material && child.material.name === 'custom_color') {
          this.colorPicker.setValue(`#${child.material.color.getHexString()}`);
        }
      });

      if (object.wallElement) {
        document.getElementById(`${this.prefix}AngleObject`).classList.add('hiddenClass');
      } else {
        document.getElementById(`${this.prefix}AngleObject`).classList.remove('hiddenClass');
      }
    }
  }

  setObjectColor = (color) => {
    this.editObject && this.editObject.traverse(function (child) {
      if (child.material && child.material.name === 'custom_color') {
        child.material.color = new Color(color);
      }
    });
  }

  removeObject = () => {
    this.editObject.parent.remove(this.editObject);
    this.editObject = null;
    this.setEditMode(false);
  }

  addObjectToWorld = (e) => {
    const targetElement = e.currentTarget;
    const posiiton = targetElement.hasAttribute('data-isWallModel') && this.positionOnWall();
    this.loadObject(
      targetElement.getAttribute('data-model'),
      targetElement.getAttribute('data-objectIndex'),
      targetElement.getAttribute('data-selectObjectIndex'),
      posiiton,
      targetElement.hasAttribute('data-bottom_colision'),
      targetElement.hasAttribute('data-top_colision')
    );
    this.hideModal();
  }

  setEditMode = (value) => {
    if (value) {
      document.getElementById(`${this.prefix}DefaultControlsContainer`).classList.add('hiddenClass');
      document.getElementById(`${this.prefix}ModelControls`).classList.remove('hiddenClass');
    } else {
      document.getElementById(`${this.prefix}DefaultControlsContainer`).classList.remove('hiddenClass');
      document.getElementById(`${this.prefix}ModelControls`).classList.add('hiddenClass');
    }
  }

  showModal = () => {
    this.modalWrapper.style.visibility = 'visible';
  }

  hideModal = () => {
    this.modalWrapper.style.visibility = 'hidden';
  }
}
