import { EdgePiece } from './EdgePiece';
import { Color, TextureLoader, MeshBasicMaterial, Float32BufferAttribute, Mesh, BufferGeometry } from 'three';
import edgeTextureHome from '../../assets/textures/edges/HOME-Edge.jpg';
import edgeTexturePro from '../../assets/textures/edges/PRO-Edge.jpg';
export class Edge extends EdgePiece {
  static textureEdgePro = new TextureLoader().load(edgeTexturePro);
  static textureEdgeHome = new TextureLoader().load(edgeTextureHome);
  static textureEdge = Edge.textureEdgePro;
  static selectedbaseColor = EdgePiece.defaultEdgeColor;
  static materialEdge = new MeshBasicMaterial({ map: Edge.textureEdge, color: Edge.selectedbaseColor });
  static changedEdgeColors = new Set();

  constructor (index, tileSize, edgeSize, orientationRow = true, positiveDirection = true) {
    super();
    this.name = 'Edge';
    this.tileIndex = index;
    this.positiveDirection = positiveDirection;
    this.orientationRow = orientationRow;

    const edgeGeom = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          0, 0, 0,
          tileSize, 0, edgeSize,
          tileSize, 0, 0,
          0, 0, 0,
          0, 0, edgeSize,
          tileSize, 0, edgeSize
        ],
        3
      )
    );

    edgeGeom.setAttribute(
      'uv',
      new Float32BufferAttribute(
        [
          0, 1,
          1, 0,
          1, 1,
          0, 1,
          0, 0,
          1, 0
        ],
        2
      )
    );

    const edgeGeomExtraPart = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          0, 0, edgeSize,
          tileSize, 0, tileSize,
          tileSize, 0, edgeSize,
          0, 0, edgeSize,
          0, 0, tileSize,
          tileSize, 0, tileSize
        ],
        3
      )
    );

    this.edgeMesh = new Mesh(edgeGeom, Edge.materialEdge);
    this.edgeMesh.tileIndex = index;
    this.edgeMesh.add(new Mesh(edgeGeomExtraPart, EdgePiece.materialEdgeExtraPart));

    if (orientationRow) {
      if (positiveDirection) {
        this.edgeMesh.rotation.y = Math.PI;
        this.edgeMesh.position.setZ(tileSize);
        this.edgeMesh.position.setX(tileSize);
      }
    } else {
      if (positiveDirection) {
        this.edgeMesh.rotation.y = -Math.PI / 2;
        this.edgeMesh.position.setX(tileSize);
      } else {
        this.edgeMesh.rotation.y = Math.PI / 2;
        this.edgeMesh.position.setZ(tileSize);
      }
    }

    this.add(this.edgeMesh);
  }

  changeColor = (newColor) => {
    this.newColor = newColor;
    this.edgeMesh.material = new MeshBasicMaterial({ map: Edge.textureEdge, color: new Color(newColor) });
    Edge.changedEdgeColors.add(this);
  }

  static changeAllEdgeColors = (color) => {
    Edge.selectedbaseColor = color;
    Edge.materialEdge.color = new Color(color);
    Edge.changedEdgeColors.forEach(edge => {
      edge.edgeMesh.material = Edge.materialEdge;
    });
    Edge.changedEdgeColors.clear();
  }

  static changeBaseTexture = (textureType) => {
    Edge.textureEdge = textureType === 'pro' ? Edge.textureEdgePro : Edge.textureEdgeHome;
    Edge.materialEdge.map = Edge.textureEdge;
  }
}
