import eye from '../assets/images/icons/landing_page/eye.svg';
import arrowDown from '../assets/images/icons/landing_page/arrow_down.svg';
import { fetchPost } from '../api/api.js';
import { initEditor, removeFormError, removeCurrentForm, initialized } from '../index.js';
import { showPassword, appendErrorDiv } from './shared.js';
import { displayTooltip, hideTooltip } from '../assets/helpers/tooltip.js';
import { loadPhoneCodes } from '../assets/phoneCodes/phoneCodes.js';
import { loadStates } from '../assets/USStates/states.js';
import { setLocalStorage } from '../assets/helpers/localStorage.js';
import { loadUserMenu } from './userMenu.js';
import { showHeader } from './header.js';
import { handleDisplayDrawer, hideDrawer } from './drawer';
import NoticePopup from '../models/uiPopups/notice';
import Localization from '../assets/localization';
import { Loader } from '../models/Loader';

export function loadRegisterFormHTML () {
  const form = document.getElementById('lp_drawer_form');
  form.innerHTML = `
    <label class="lp_drawer_form_label">Sign Up</label>
    <div class="lp_drawer_row_container">
      <div class="lp_drawer_row_left_input">
        <label class="lp_drawer_label" for="first_name">First Name</label>
        <input type="text" id="first_name" name="first_name" class="lp_drawer_input" placeholder="First Name">
      </div>
      <div class="lp_drawer_row_input">
        <label class="lp_drawer_label" for="last_name">Last Name</label>
        <input type="text" id="last_name" name="last_name" class="lp_drawer_input" placeholder="Last Name">
      </div>
    </div>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="email">Email</label>
      <input type="text" id="email" name="email" class="lp_drawer_input" placeholder="Email">
    </div>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="password">Password</label>
      <input type="password" id="password" name="password" autocomplete="on" class="lp_drawer_input" placeholder="Password">
      <img src=${eye} class="lp_drawer_password_eye" />
    </div>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="confirm_password">Confirm Password</label>
      <input type="password" id="confirm_password" name="confirm_password" autocomplete="on" class="lp_drawer_input" placeholder="Confirm Password">
      <img src=${eye} class="lp_drawer_password_eye" />
    </div>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="select_state">State</label>
      <div class="lp_drawer_select_container">
        <select id="state" class="lp_drawer_select" name="state" placeholder="State">
          ${loadStates()}
        </select>
        <img src=${arrowDown} class="lp_drawer_select_arrow" />
      </div>
    </div>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="phone">Phone Number</label>
      <div class="lp_drawer_row_container">
        <div class="lp_drawer_select_container">
          <select id="phone_code" class="lp_drawer_select" name="phone_code" placeholder="Phone Code">
            ${loadPhoneCodes()}
          </select>
          <img src=${arrowDown} class="lp_drawer_select_arrow" />
        </div>
        <input id="phone" class="lp_drawer_input" type="text" name="phone" autocomplete="on" placeholder="Phone Number">
      </div>
    </div>
    <button id="register_button" class="submit_button">Sign Up</button>
  `;

  addPasswordInputListener();
  addRegisterButtonListener();
  addShowPasswordListeners();
  stateOnChange();
}

function addPasswordInputListener () {
  const passwordInput = document.getElementById('password');
  passwordInput.addEventListener('mouseover', () => displayTooltip(passwordInput, 'Password must be at least 8 characters long.'));
  passwordInput.addEventListener('mouseleave', hideTooltip);
}

function addRegisterButtonListener () {
  const registerButton = document.getElementById('register_button');
  registerButton.addEventListener('click', register);
}

function addShowPasswordListeners () {
  const eyes = document.querySelectorAll('.lp_drawer_password_eye');
  eyes.forEach(eye => {
    eye.addEventListener('click', showPassword);
  });
}

function stateOnChange () {
  const stateSelect = document.getElementById('state');
  const phoneCodeSelect = document.getElementById('phone_code');
  stateSelect.onchange = () => { if (stateSelect.value !== 'Other') phoneCodeSelect.value = '+1'; };
}

const loader = new Loader();

function register () {
  loader.show();
  const password = document.getElementById('password').value;
  const confirmPassword = document.getElementById('confirm_password').value;
  if (password !== confirmPassword) {
    loader.hide();
    showRegisterError(204);
    return;
  }

  const email = document.getElementById('email').value.toLowerCase();
  const firstName = document.getElementById('first_name').value;
  const lastName = document.getElementById('last_name').value;
  const state = document.getElementById('state').value;
  const phone = document.getElementById('phone').value;
  const phoneCode = document.getElementById('phone_code').value;

  const user = {
    email: email,
    password: password,
    first_name: firstName,
    last_name: lastName,
    state: state,
    phone: `${phoneCode}${phone}`
  };

  fetchPost(user, '/users/register')
    .then(response => {
      if (response.error) {
        showRegisterError(response.error.code);
      } else {
        setLocalStorage('user', {
          first_name: firstName,
          last_name: lastName,
          email: email.toLowerCase(),
          state: state,
          phone: phone ? `${phoneCode}${phone}` : ''
        });
        if (initialized) {
          showHeader();
          handleDisplayDrawer();
        } else {
          initEditor();
          handleDisplayDrawer();
        }
        removeCurrentForm();
        loadUserMenu();
      }
    }).catch(async () => {
      const localization = new Localization();
      hideDrawer();
      await new NoticePopup(localization.strings.errorNotice);
    }).finally(() => {
      loader.hide();
    });
}

function showRegisterError (code) {
  removeFormError();
  switch (code) {
    case 201:
      appendErrorDiv('The email address that You entered is invalid.');
      break;
    case 203:
      appendErrorDiv('A user with that email address already exists.');
      break;
    case 202:
      appendErrorDiv('Password must be at least 8 characters long.');
      break;
    case 204:
      appendErrorDiv('Passwords do not match!');
      break;
    case 205:
      appendErrorDiv('Invalid phone number.');
      break;
    case 206:
      appendErrorDiv('Please enter Your full name.');
      break;
    case 207:
      appendErrorDiv('Please enter Your phone number.');
      break;
    case 211:
      appendErrorDiv('Invalid state selection.');
  }
}
