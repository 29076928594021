/**
 *
 * @param {HTMLElement} element - `parent element must have position relative`
 * @param {string} message
 */
export function displayTooltip (element, message) {
  const parent = element.parentElement;
  const tooltip = document.createElement('div');
  tooltip.id = 'tooltip';
  tooltip.className = 'tooltip';
  tooltip.innerText = message;
  parent.appendChild(tooltip);
}

export function hideTooltip () {
  const tooltip = document.getElementById('tooltip');
  tooltip && tooltip.remove();
}
