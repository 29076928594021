import { Color, TextureLoader, Float32BufferAttribute, Mesh, BufferGeometry, MeshBasicMaterial } from 'three';
import { config } from '../../config/config';
export class Tile extends Mesh {
  static textures = config.tiles.map(tile => {
    const texture = new TextureLoader().load(tile.texture);
    texture.textureId = tile.id;
    tile.textureColorPalette && (texture.vinylTextureId = 0);
    return texture;
  });

  static vinylTextures = [];
  static kita = config.tiles.map((tile, tileIndex) => {
    if (!tile.textureColorPalette) return;
    Tile.vinylTextures[tileIndex] = tile.colorPalette.map((vinylTexture, textureIndex) => {
      const texture = new TextureLoader().load(vinylTexture);
      texture.textureId = tileIndex;
      texture.vinylTextureId = textureIndex;
      return texture;
    });
  });

  static selectedTextureId = 0;
  static selectedbaseColor = config.defaultTileColor;
  static selectedTexture = Tile.textures[Tile.selectedTextureId];
  static selectedVynilTexture = null;
  static materialTile = new MeshBasicMaterial({ map: Tile.selectedTexture, color: Tile.selectedbaseColor });
  static changedMaterials = new Set();

  constructor (index, tileSize, tileColor = undefined, textureId = -1) {
    super();
    this.name = 'Tile';
    this.tileIndex = index;

    this.tileSize = tileSize;
    this.geometry = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          0, 0, 0,
          tileSize, 0, tileSize,
          tileSize, 0, 0,
          0, 0, 0,
          0, 0, tileSize,
          tileSize, 0, tileSize
        ],
        3
      )
    );

    this.geometry.setAttribute(
      'uv',
      new Float32BufferAttribute(
        [
          0, 1,
          1, 0,
          1, 1,
          0, 1,
          0, 0,
          1, 0
        ],
        2
      )
    );

    if (tileColor) {
      this.material = this.changeColor(tileColor, textureId);
    } else {
      this.material = Tile.materialTile;
    }
  }

  changeColor = (newColor, textureId = -1) => {
    this.newColor = newColor;
    this.textureId = textureId;
    if (typeof newColor === 'number') {
      let texture;
      if (Tile.vinylTextures[textureId]) {
        texture = Tile.vinylTextures[textureId][newColor];
      } else if (Tile.vinylTextures[Tile.selectedTextureId]) {
          texture = Tile.vinylTextures[Tile.selectedTextureId][newColor];
      } else {
          texture = Tile.selectedTexture;
      }
      this.material = new MeshBasicMaterial({ map: texture });
    } else {
      const texture = textureId >= 0 ? Tile.textures[textureId] : Tile.selectedTexture;
      this.material = new MeshBasicMaterial({ map: texture, color: new Color(newColor) });
    }
    Tile.changedMaterials.add(this);
  }

  static changeBaseTexture = (textureId) => {
    Tile.selectedTextureId = textureId;
    Tile.selectedTexture = Tile.textures[textureId];
    Tile.materialTile.map = Tile.selectedTexture;
    config.tiles[textureId].textureColorPalette && (Tile.changeBaseColor('#ffffff'));
  }

  static changeBaseColor = (newColor, textureId = -1) => {
    if (typeof newColor === 'number') {
      let texture;
      if (Tile.vinylTextures[textureId]) {
        texture = Tile.vinylTextures[textureId][newColor];
      } else if (Tile.vinylTextures[Tile.selectedTextureId]) {
        texture = Tile.vinylTextures[Tile.selectedTextureId][newColor];
      } else {
        texture = Tile.selectedTexture;
      }

      Tile.materialTile.map = texture;
      Tile.selectedbaseColor = '#ffffff';
      Tile.selectedVynilTexture = newColor;
    } else {
      Tile.materialTile.color = new Color(newColor);
      Tile.selectedbaseColor = newColor;
      Tile.selectedVynilTexture = null;
    }
  }
}
