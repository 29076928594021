import Localization from '../../assets/localization';

export class Panels {
  constructor () {
    this.localization = new Localization();
  }

  hide = () => {
    this.container.style.visibility = 'hidden';
  }

  show = () => {
    this.container.style.visibility = 'visible';
  }

  /**
   * Create event listener for elemment
   * @param {*} el DOM element
   * @param {*} eventType input, click etc...
   * @param {*} callback callback function
   * @returns bool
   */
  createEventListener = (el, eventType, callback) => {
    return el && el.addEventListener(eventType, callback, false);
  }
}
