import ObjectModelControls from '../ObjectModelControls';

export default class IsometricControlPanel extends ObjectModelControls {
  constructor (contolFunction) {
    super();
    this.panel = null;
    this.parentElement = document.getElementById('isometricControl');
    this.isoSetFunction = contolFunction;
  }

  create = (panelName, checked = false, x = 0, y = 0) => {
    !this.panel && (this.panel = this.createNewPanel(panelName, x, y, this.parentElement));
    this.createOption(checked);
  }

  createOption = (value, optionName = 'iso3D') => {
    this.optionName = optionName;
    this.panel.addBooleanSwitch(this.optionName, value, this.onValueChange, '2D', '3D');
  }

  onValueChange = (value) => {
    this.isoSetFunction(value);
    return value;
  }

  getValue = (optionName = 'iso3D') => {
    return this.panel.getValue(optionName);
  }

  setValue = (value) => {
    this.panel.setValue(this.optionName, value);
  }

  hide = () => {
    this.panel.hide();
  }

  show = () => {
    this.panel.show();
  }

  remove = () => {
    this.panel.destroy();
  }
}
