import { removeFormError } from '../index.js';

export function showPassword (e) {
  const input = e.srcElement.previousElementSibling;
  input.type === 'password'
    ? input.type = 'text'
    : input.type = 'password';
}

export function appendErrorDiv (message) {
  const error = document.getElementById('lp_drawer_error');
  error && removeFormError();

  const errorDiv = document.createElement('div');
  errorDiv.id = 'lp_drawer_error';
  errorDiv.innerHTML = `<span class="lp_drawer_error_star">*</span>${message}`;
  const form = document.getElementById('lp_drawer_form_container');
  form.appendChild(errorDiv);
}
