import ObjectModelControls from '../ObjectModelControls';

export default class ArrowControlPanel extends ObjectModelControls {
  constructor (onChange) {
    super();
    this.panel = null;
    this.parentElement = this.createArrowWrapper();
    this.onChange = onChange;
  }

  createArrowWrapper = () => {
    return this.createWrappingElement('div', 'arrowsControl');
  }

  addTitleNameForPanel = () => {
    this.panel.addTextDesriptionForPanel('arrowsControl', this.parentElement, this.localization.strings.movePattern);
  }

  create = (panelName, x = 0, y = 0) => {
    !this.panel && (this.panel = this.createNewPanel(panelName, x, y, this.parentElement));
    this.createTileSelectorOption();
    this.addTitleNameForPanel();
  }

  createTileSelectorOption = (optionName = 'arrowsControl') => {
    this.arrowOptionName = optionName;
    this.panel.arrowControls(this.arrowOptionName, this.onArrowClick);
  }

  onArrowClick = (value) => {
    this.onChange(value);
  }

  hide = () => {
    this.panel.hide();
  }

  show = () => {
    this.panel.show();
  }

  remove = () => {
    this.panel.destroy();
  }
}
