import Localization from './../assets/localization/';
import sweestype1 from '../assets/images/icons/tiles/ribtraxPro.png';
import sweestype2 from '../assets/images/icons/tiles/ribtraxProSmooth.png';
import sweestype3 from '../assets/images/icons/tiles/vinyltraxPro.png';
import tileBg1 from './../assets/images/icons/tiles/Ribtrax-Pro-bg.jpg';
import tileBg2 from './../assets/images/icons/tiles/Ribtrax-Smooth-Pro-bg.jpg';
import tileBg3 from './../assets/images/icons/tiles/Vinyltrax-pro-bg.jpg';
import ribtraxTexture from './../assets/textures/tiles/ribtrax.jpg';
import ribtraxSmoothTexture from './../assets/textures/tiles/ribtraxSmooth.jpg';
import ribtraxSmoothHomeTexture from './../assets/textures/tiles/ribtraxSmoothHome.jpg';
import diamondtraxTexture from './../assets/textures/tiles/diamondtrax.jpg';
import vinyltraxPolishedConcreteTexture from './../assets/textures/tiles/PolishedConcrete.jpg';
import vinyltraxAshPineTexture from './../assets/textures/tiles/vinyltraxAshPine.jpg';
import vinyltraxBlackOakTexture from './../assets/textures/tiles/vinyltraxBlackOak.jpg';
import vinyltraxCarbonFiberTexture from './../assets/textures/tiles/vinyltraxCarbonFiber.jpg';
import vinyltraxLightMapleTexture from './../assets/textures/tiles/vinyltraxLightMaple.jpg';
import vinyltraxReclaimedPineTexture from './../assets/textures/tiles/vinyltraxReclaimedPine.jpg';
import vinyltraxParquetTexture from './../assets/textures/tiles/vinyltraxParquet.jpg';
import { tilePatterns } from './../assets/svgPatternFiles/tilePatterns';

const storeUrl = 'https://www.obsessedgarage.com/';
const apiEndpointUrl = process.env.ENV === 'dev' ? 'http://localhost:3000' : 'https://api.floordesigner.obsessedgarage.com';
const localization = new Localization();
const numOfTilesPerSide = 54;
const footToMeter = 0.3048;
const relativeRowIndex2 = numOfTilesPerSide;
const relativeRowIndex3 = numOfTilesPerSide * 2;
const relativeRowIndex4 = numOfTilesPerSide * 3;
const relativeRowIndex5 = numOfTilesPerSide * 4;
const relativeRowIndex6 = numOfTilesPerSide * 5;
const relativeRowIndex7 = numOfTilesPerSide * 6;
const relativeRowIndex8 = numOfTilesPerSide * 7;
const relativeRowIndex9 = numOfTilesPerSide * 8;
const relativeRowIndex10 = numOfTilesPerSide * 9;
const relativeRowIndex11 = numOfTilesPerSide * 10;
const relativeRowIndex12 = numOfTilesPerSide * 11;
const relativeRowIndex13 = numOfTilesPerSide * 12;
const relativeRowIndex14 = numOfTilesPerSide * 13;
const relativeRowIndex15 = numOfTilesPerSide * 14;

export const config = {
  storeUrl: storeUrl,
  storeCartUrl: `${storeUrl}cart/`,
  apiEndpointUrl: apiEndpointUrl,
  performanceMonitor: false,
  performanceMonitorThree: false,
  placeEdgesOnWalls: true,
  pointInWallsTolerance: 0,
  snapPrecision: 0.25,
  numOfTilesPerSide: numOfTilesPerSide,
  footToMeter: footToMeter,
  wallThickness: footToMeter * Math.sqrt(2) * 0.25,
  concreteColor: '#e9e9e9',
  worldColor: '#e9e9e9',
  defaultTileSize: 0.4,
  defaultEdgeSize: 0.07,
  defaultTileColor: '#9f9f9f',
  defaultTileChangeColor: '#cf102d',
  defaultEdgeColor: '#cf102d',
  defaultEdgeChangeColor: '#212121',
  distanceLineColor: '#e5171a',
  uiControls: {
    enabled: true
  },
  specColorsPrice: 'Specialty Colors:&nbsp<div style="display: flex"><div style="color: #0054a6cc">$6.75</div><div style="font-size: 15px; font-weight: 300; margin-top: 5px;">/sqft</div></div>',
  standardColorsPrice: 'Standard Colors',
  discountCodes: [
    { name: '0%', id: null }
  ],
  productPacks: {
    0: 6,
    1: 6,
    2: 10,
    loopedEdges: 10,
    peggedEdges: 10,
    corners: 4
  },
  allColorPalettePro: [
    { color: '#ffffff', name: 'ARCTIC WHITE' },
    { color: '#b0b9bd', name: 'PEARL SILVER' },
    { color: '#9f9f9f', name: 'PEARL GREY' },
    { color: '#7c7c7c', name: 'SLATE GREY' },
    { color: '#212121', name: 'JET BLACK' },
    { color: '#65402f', name: 'CHOCOLATE BROWN' },
    { color: '#aa5935', name: 'TERRA COTTA' },
    { color: '#ddb57f', name: 'MOCHA JAVA' },
    { color: '#fcd900', name: 'CITRUS YELLOW' },
    { color: '#72a031', name: 'TURF GREEN' },
    { color: '#008175', name: 'TEAL' },
    { color: '#225e8f', name: 'ISLAND BLUE' },
    { color: '#002e6b', name: 'ROYAL BLUE' },
    { color: '#6f2e89', name: 'COSMIC PURPLE' },
    { color: '#e84b93', name: 'CARNIVAL PINK' },
    { color: '#cf102d', name: 'RACING RED' },
    { color: '#dc4405', name: 'TROPICAL ORANGE' },
    { color: '#96d700', name: 'TECHNO GREEN' },
    { color: '#5d432c', name: 'WALNUT BROWN' },

    // viniltrax pattern simulated colors for svg
    { color: '#515151', name: 'BLACK OAK' },
    { color: '#cfc4ad', name: 'LIGHT MAPLE' },
    { color: '#757367', name: 'RECLAIMED PINE' },
    { color: '#414141', name: 'CARBON FIBER' },
    { color: '#a39787', name: 'ASH PINE' },
    { color: '#b6b3b2', name: 'POLISHED CONCRETE' },
    { color: '#e0c595', name: 'PARQUET' },

    // some colors
    { color: '#f5f7f2', name: 'Chantilly Lace' },
    { color: '#efede2', name: 'Swiss Coffe' },
    { color: '#e4e1d8', name: 'Classic Gray' },
    { color: '#e7e5e0', name: 'Calm' },
    { color: '#dad4c5', name: 'Edgecomb Gray' },
    { color: '#b9bbb7', name: 'Coventry Gray' },
    { color: '#dcd3bd', name: 'Manchester Tan' },
    { color: '#c2d2ca', name: 'Palladian Blue' },
    { color: '#f6e2a5', name: 'Hawthorne Yellow' },
    { color: '#b2b8a3', name: 'Saybrook Sage' },
    { color: '#d2c3a8', name: 'Shaker Beige' },
    { color: '#e9dad5', name: 'Proposal' },
    { color: '#deb774', name: 'Dorset Gold' },
    { color: '#434b56', name: 'Hale Navy' },
    { color: '#a4937d', name: 'Alexandria Beige' },
    { color: '#686763', name: 'Kendall Charcoal' },
    { color: '#8b2829', name: 'Caliente' },
    { color: '#524b59', name: 'Shadow' },
    { color: '#4a4b4c', name: 'Wrought Iron' },
    { color: '#000000', name: 'Black' }

  ],
  edgePiecesColorPalette: {
    pro: [
      '#cf102d',
      '#212121',
      '#9f9f9f',
      '#7c7c7c',
      '#b0b9bd',
      '#ffffff',
      '#002e6b',
      '#dc4405',
      '#65402f',
      '#ddb57f',
      '#fcd900',
      '#5d432c'
    ],
    home: [
      '#cf102d',
      '#212121',
      '#7c7c7c',
      '#b0b9bd',
      '#ffffff',
      '#002e6b',
      '#dc4405',
      '#65402f',
      '#ddb57f',
      '#fcd900'
    ]
  },
  startingShapes: [
    [
      [0, 0, 0],
      [4.54, 0, 0],
      [4.54, 0, 5.34],
      [0, 0, 5.34]
    ],
    [
      [0, 0, 0],
      [4.94, 0, 0],
      [4.94, 0, 2.54],
      [2.54, 0, 2.54],
      [2.54, 0, 5.34],
      [0, 0, 5.34]
    ],
    [
      [0, 0, 0],
      [5.34, 0, 0],
      [5.34, 0, 2.14],
      [4.14, 0, 2.14],
      [4.14, 0, 5.34],
      [1.2, 0, 5.34],
      [1.2, 0, 2.14],
      [0, 0, 2.14]
    ],
    [
      [0, 0, 0],
      [4.54, 0, 0],
      [4.54, 0, 5.34],
      [3.2, 0, 5.34],
      [3.2, 0, 2.94],
      [1.34, 0, 2.94],
      [1.34, 0, 5.34],
      [0, 0, 5.34]
    ],
    [
      [1, 0, 0],
      [3.54, 0, 0],
      [3.54, 0, 0.8],
      [4.34, 0, 0.8],
      [4.34, 0, 3.74],
      [3.54, 0, 3.74],
      [3.54, 0, 4.54],
      [1, 0, 4.54],
      [1, 0, 3.74],
      [0.2, 0, 3.74],
      [0.2, 0, 0.8],
      [1, 0, 0.8]
    ],
    [
      [2, 0, 0],
      [3.74, 0, 0],
      [3.74, 0, 0.8],
      [4.54, 0, 0.8],
      [4.54, 0, 1.6],
      [5.34, 0, 1.6],
      [5.34, 0, 3.34],
      [4.54, 0, 3.34],
      [4.54, 0, 4.14],
      [3.74, 0, 4.14],
      [3.74, 0, 4.94],
      [2, 0, 4.94],
      [2, 0, 4.14],
      [1.2, 0, 4.14],
      [1.2, 0, 3.34],
      [0.4, 0, 3.34],
      [0.4, 0, 1.6],
      [1.2, 0, 1.6],
      [1.2, 0, 0.8],
      [2, 0, 0.8]
    ]
  ],
  tiles: [
    {
      id: 0,
      price: '$5.87',
      priceUnit: '/sqft',
      title: localization.strings.tile_1_title,
      desc: localization.strings.tile_1_desc,
      img_src: sweestype1,
      bg_img: tileBg1,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_m : localization.strings.tile_dimensions_ft,
      tileType: 'pro', // large
      texture: ribtraxTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#9f9f9f', '#fcd900', '#002e6b', '#cf102d'],
      colorPalette: [
        '#ffffff',
        '#b0b9bd',
        '#9f9f9f',
        '#7c7c7c',
        '#212121',
        '#65402f',
        '#aa5935',
        '#ddb57f',
        '#fcd900',
        '#72a031',
        '#008175',
        '#225e8f',
        '#002e6b',
        '#6f2e89',
        '#e84b93',
        '#cf102d',
        '#dc4405',
        '#96d700'
      ],
      variantIds: {
        /* eslint-disable */
        'ffffff': 39873991868567,
        'b0b9bd': 39873992196247,
        '9f9f9f': 39873992163479,
        '7c7c7c': 39873992294551,
        '212121': 39873992097943,
        '65402f': 39873991934103,
        'aa5935': 39873992425623,
        'ddb57f': 39873992130711,
        'fcd900': 39873991966871,
        '72a031': 39873992556695,
        '008175': 39873992327319,
        '225e8f': 39873992032407,
        '002e6b': 39873992261783,
        '6f2e89': 39873991999639,
        'e84b93': 39873991901335,
        'cf102d': 39873992229015,
        'dc4405': 39873992491159,
        '96d700': 39873992360087
        /* eslint-enable */
      }
    },
    {
      id: 1,
      price: '5.87',
      priceUnit: '/sqft',
      title: localization.strings.tile_2_title,
      desc: localization.strings.tile_2_desc,
      img_src: sweestype2,
      bg_img: tileBg2,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_m : localization.strings.tile_dimensions_ft,
      tileType: 'pro',
      texture: ribtraxSmoothTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#b0b9bd', '#fcd900', '#002e6b', '#cf102d'],
      colorPalette: [
        '#ffffff',
        '#b0b9bd',
        '#7c7c7c',
        '#212121',
        '#65402f',
        '#ddb57f',
        '#fcd900',
        '#002e6b',
        '#cf102d',
        '#dc4405'
      ],
      variantIds: {
        /* eslint-disable */
        'ffffff': 39874091581591,
        'b0b9bd': 39874091516055,
        '7c7c7c': 39874091483287,
        '212121': 39874091450519,
        '65402f': 39874091647127,
        'ddb57f': 39874091614359,
        'fcd900': 39874091745431,
        '002e6b': 39874091679895,
        'cf102d': 39874091548823,
        'dc4405': 39874091712663
        /* eslint-enable */
      }
    },
    {
      id: 2,
      price: '9.12',
      priceUnit: '/sqft',
      title: localization.strings.tile_3_title,
      desc: localization.strings.tile_3_desc,
      img_src: sweestype3,
      bg_img: tileBg3,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_m : localization.strings.tile_dimensions_ft,
      tileType: 'pro',
      texture: vinyltraxBlackOakTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 2,
      defaultPatternColors: ['#515151', '#cfc4ad', '#757367', '#a39787'],
      colorPalette: [
        vinyltraxBlackOakTexture,
        vinyltraxLightMapleTexture,
        vinyltraxReclaimedPineTexture,
        // vinyltraxCarbonFiberTexture,
        vinyltraxAshPineTexture,
        vinyltraxPolishedConcreteTexture
      ],
      variantIds: {
        0: 39874130641047,
        1: 39874130673815,
        2: 39874130706583,
        3: 39874130608279,
        4: 39874130739351,
        // 5: 20389973262390
      },
      mapColorsToPattern: ['#515151', '#cfc4ad', '#757367', '#a39787', '#b6b3b2']
    },
  ],
  edgeVariantIds: {
    /* eslint-disable */
    pro: {
      corners: {
        'ffffff': 39873864892567,
        'cf102d': 39873865154711,
        '212121': 39873864990871,
        '9f9f9f': 39873865089175,
        '7c7c7c': 39873865220247,
        'b0b9bd': 39873865121943,
        '002e6b': 39873865187479,
        'dc4405': 39873865253015,
        'fcd900': 39873865285783,
        '65402f': 39873864925335,
        'ddb57f': 39873865056407,
        '5d432c': 39873865318551 // 39873865318551,Walnut Brown
      },
      edgesLooped: {
        'ffffff': 39873937277079,
        'cf102d': 39873937506455,
        '212121': 39873937375383,
        '9f9f9f': 39873937440919,
        '7c7c7c': 39873937571991,
        'b0b9bd': 39873937473687,
        '002e6b': 39873937539223,
        'dc4405': 39873937604759,
        'fcd900': 39873937342615,
        '65402f': 39873937309847,
        'ddb57f': 39873937408151,
        '5d432c': 39873937637527 // 39873937637527,Walnut Brown
      },
      edgesPegged: {
        'ffffff': 39873975091351,
        'cf102d': 39873975320727,
        '212121': 39873975189655,
        '9f9f9f': 39873975255191,
        '7c7c7c': 39873975386263,
        'b0b9bd': 39873975287959,
        '002e6b': 39873975353495,
        'dc4405': 39873975451799,
        'fcd900': 39873975156887,
        '65402f': 39873975124119,
        'ddb57f': 39873975222423,
        '5d432c': 39873975484567 // 39873975484567,Walnut Brown
      }
    }
    /* eslint-enable */
  },
  tilePatterns: [
    { id: 0, svg: tilePatterns[0], colors: 1 },
    { id: 1, svg: tilePatterns[1], colors: 2 },
    { id: 2, svg: tilePatterns[2], colors: 3 },
    { id: 3, svg: tilePatterns[3], colors: 2 },
    { id: 4, svg: tilePatterns[4], colors: 2 },
    { id: 5, svg: tilePatterns[5], colors: 3 },
    { id: 6, svg: tilePatterns[6], colors: 3 },
    { id: 7, svg: tilePatterns[7], colors: 2 },
    { id: 8, svg: tilePatterns[8], colors: 4 }
  ],
  patterns: [
    {
      repeat: false,
      width: 0,
      height: 0,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: true,
      width: 2,
      height: 2,
      primaryColorRelativeIndexes: [
        [0],
        [relativeRowIndex2 + 1]
      ],
      secondaryColorRelativeIndexes: [
        [1],
        [relativeRowIndex2]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: true,
      width: 3,
      height: 3,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 2],
        [],
        [relativeRowIndex3, relativeRowIndex3 + 2]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1]
      ],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 5, relativeRowIndex2 + 6, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 5, relativeRowIndex3 + 6, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 5, relativeRowIndex4 + 6, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 5, relativeRowIndex5 + 6, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 5, relativeRowIndex6 + 6, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 5, relativeRowIndex7 + 6, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 5, relativeRowIndex8 + 6, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 5, relativeRowIndex9 + 6, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 5, relativeRowIndex10 + 6, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9, relativeRowIndex10 + 10],
        [relativeRowIndex11, relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9, relativeRowIndex11 + 10]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 10],
        [relativeRowIndex11, relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9, relativeRowIndex11 + 10]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9],
        [relativeRowIndex2, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 10],
        [relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 5, relativeRowIndex2 + 6, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9],
        [relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 5, relativeRowIndex3 + 6, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9],
        [relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 5, relativeRowIndex4 + 6, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9],
        [relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 5, relativeRowIndex5 + 6, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9],
        [relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 5, relativeRowIndex6 + 6, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9],
        [relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 5, relativeRowIndex7 + 6, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9],
        [relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 5, relativeRowIndex8 + 6, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9],
        [relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 5, relativeRowIndex9 + 6, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9],
        [relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 5, relativeRowIndex10 + 6, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9]
      ],
      quaternaryColorRelativeIndexes: [
        [0, 10],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [relativeRowIndex11, relativeRowIndex11 + 10]
      ]
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [1, 2, 3, 7, 8, 9],
        [relativeRowIndex2, relativeRowIndex2 + 4, relativeRowIndex2 + 6, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 4, relativeRowIndex3 + 6, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 4, relativeRowIndex4 + 6, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 4, relativeRowIndex5 + 6, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 4, relativeRowIndex6 + 6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 4, relativeRowIndex7 + 6, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 4, relativeRowIndex8 + 6, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 4, relativeRowIndex9 + 6, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 4, relativeRowIndex10 + 6, relativeRowIndex10 + 10],
        [relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9],
        [relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9],
        [relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9],
        [relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9],
        [relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9],
        [relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9],
        [relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9],
        [relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9],
        [relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9]
      ],
      quaternaryColorRelativeIndexes: [
        [0, 4, 6, 10],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [relativeRowIndex11, relativeRowIndex11 + 4, relativeRowIndex11 + 6, relativeRowIndex11 + 10]
      ]
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 6, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 6, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 6, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 6, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 6, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 6, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 6, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 6, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 6, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9, relativeRowIndex10 + 10],
        [relativeRowIndex11, relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9, relativeRowIndex11 + 10]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: true,
      width: 15,
      height: 15,
      primaryColorRelativeIndexes: [
        [],
        [],
        [relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 5, relativeRowIndex3 + 6, relativeRowIndex3 + 8, relativeRowIndex3 + 10, relativeRowIndex3 + 11, relativeRowIndex3 + 12, relativeRowIndex3 + 13],
        [relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 5, relativeRowIndex4 + 6, relativeRowIndex4 + 8, relativeRowIndex4 + 10, relativeRowIndex4 + 11, relativeRowIndex4 + 12, relativeRowIndex4 + 13],
        [],
        [],
        [relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 5, relativeRowIndex7 + 6, relativeRowIndex7 + 8, relativeRowIndex7 + 10, relativeRowIndex7 + 11, relativeRowIndex7 + 12, relativeRowIndex7 + 13],
        [relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 5, relativeRowIndex8 + 6, relativeRowIndex8 + 8, relativeRowIndex8 + 10, relativeRowIndex8 + 11, relativeRowIndex8 + 12, relativeRowIndex8 + 13],
        [],
        [relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 5, relativeRowIndex10 + 6, relativeRowIndex10 + 8, relativeRowIndex10 + 10, relativeRowIndex10 + 11, relativeRowIndex10 + 12, relativeRowIndex10 + 13],
        [relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 8, relativeRowIndex11 + 10, relativeRowIndex11 + 11, relativeRowIndex11 + 12, relativeRowIndex11 + 13],
        [],
        [],
        [relativeRowIndex14 + 1, relativeRowIndex14 + 2, relativeRowIndex14 + 5, relativeRowIndex14 + 6, relativeRowIndex14 + 8, relativeRowIndex14 + 10, relativeRowIndex14 + 11, relativeRowIndex14 + 12, relativeRowIndex14 + 13],
        [relativeRowIndex15 + 1, relativeRowIndex15 + 2, relativeRowIndex15 + 5, relativeRowIndex15 + 6, relativeRowIndex15 + 8, relativeRowIndex15 + 10, relativeRowIndex15 + 11, relativeRowIndex15 + 12, relativeRowIndex15 + 13]
      ],
      secondaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 5, relativeRowIndex2 + 6, relativeRowIndex2 + 8, relativeRowIndex2 + 10, relativeRowIndex2 + 11, relativeRowIndex2 + 12, relativeRowIndex2 + 13],
        [relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 7, relativeRowIndex3 + 9, relativeRowIndex3 + 14],
        [relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 7, relativeRowIndex4 + 9, relativeRowIndex4 + 14],
        [relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 5, relativeRowIndex5 + 6, relativeRowIndex5 + 8, relativeRowIndex5 + 10, relativeRowIndex5 + 11, relativeRowIndex5 + 12, relativeRowIndex5 + 13],
        [relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 5, relativeRowIndex6 + 6, relativeRowIndex6 + 8, relativeRowIndex6 + 10, relativeRowIndex6 + 11, relativeRowIndex6 + 12, relativeRowIndex6 + 13],
        [relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 7, relativeRowIndex7 + 9, relativeRowIndex7 + 14],
        [relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 7, relativeRowIndex8 + 9, relativeRowIndex8 + 14],
        [relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 5, relativeRowIndex9 + 6, relativeRowIndex9 + 8, relativeRowIndex9 + 10, relativeRowIndex9 + 11, relativeRowIndex9 + 12, relativeRowIndex9 + 13],
        [relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 7, relativeRowIndex10 + 9, relativeRowIndex10 + 14],
        [relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 7, relativeRowIndex11 + 9, relativeRowIndex11 + 14],
        [relativeRowIndex12 + 1, relativeRowIndex12 + 2, relativeRowIndex12 + 5, relativeRowIndex12 + 6, relativeRowIndex12 + 8, relativeRowIndex12 + 10, relativeRowIndex12 + 11, relativeRowIndex12 + 12, relativeRowIndex12 + 13],
        [relativeRowIndex13 + 1, relativeRowIndex13 + 2, relativeRowIndex13 + 5, relativeRowIndex13 + 6, relativeRowIndex13 + 8, relativeRowIndex13 + 10, relativeRowIndex13 + 11, relativeRowIndex13 + 12, relativeRowIndex13 + 13],
        [relativeRowIndex14 + 3, relativeRowIndex14 + 4, relativeRowIndex14 + 7, relativeRowIndex14 + 9, relativeRowIndex14 + 14],
        [relativeRowIndex15 + 3, relativeRowIndex15 + 4, relativeRowIndex15 + 7, relativeRowIndex15 + 9, relativeRowIndex15 + 14]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 7, relativeRowIndex2 + 9, relativeRowIndex2 + 14],
        [],
        [],
        [relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 7, relativeRowIndex5 + 9, relativeRowIndex5 + 14],
        [relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 7, relativeRowIndex6 + 9, relativeRowIndex6 + 14],
        [],
        [],
        [relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 7, relativeRowIndex9 + 9, relativeRowIndex9 + 14],
        [],
        [],
        [relativeRowIndex12 + 3, relativeRowIndex12 + 4, relativeRowIndex12 + 7, relativeRowIndex12 + 9, relativeRowIndex12 + 14],
        [relativeRowIndex13 + 3, relativeRowIndex13 + 4, relativeRowIndex13 + 7, relativeRowIndex13 + 9, relativeRowIndex13 + 14]
      ],
      quaternaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        [relativeRowIndex2],
        [relativeRowIndex3],
        [relativeRowIndex4],
        [relativeRowIndex5],
        [relativeRowIndex6],
        [relativeRowIndex7],
        [relativeRowIndex8],
        [relativeRowIndex9],
        [relativeRowIndex10],
        [relativeRowIndex11],
        [relativeRowIndex12],
        [relativeRowIndex13],
        [relativeRowIndex14],
        [relativeRowIndex15]
      ]
    }
  ]
};
