import Localization from '../localization';
import carSvg from '../images/icons/car.svg';
import cabinetSvg from '../images/icons/cabinet.svg';
import otherSvg from '../images/icons/other.svg';
import doorSvg from '../images/icons/door.svg';
import windowSvg from '../images/icons/windows.svg';

// CARS
import mustangModel from '../3DModels/cars/mustang.gltf';
import carLift from '../3DModels/cars/carlift.gltf';
import carLiftAndCar from '../3DModels/cars/carlift-gray-car.gltf';
import mercedes from '../3DModels/cars/mercedes.gltf';
import pickup from '../3DModels/cars/pickup-F-150.gltf';
import suv from '../3DModels/cars/porche-cayman.gltf';
import atv from '../3DModels/cars/atv.gltf';
import motorbike from '../3DModels/cars/motorbike.gltf';
import scooter from '../3DModels/cars/scooter.gltf';
import bicycle from '../3DModels/cars/bicycle.gltf';
import plane from '../3DModels/cars/plane.gltf';
import mustangImage from '../images/icons/object_icons/cars/mustang.jpg';
import carliftImage from '../images/icons/object_icons/cars/carlift.jpg';
import carliftMustangImage from '../images/icons/object_icons/cars/carlift+mustang.jpg';
import mercedesImage from '../images/icons/object_icons/cars/mercedes.jpg';
import pickupImage from '../images/icons/object_icons/cars/pickup-F-150.jpg';
import suvImage from '../images/icons/object_icons/cars/porshe-cayman.jpg';
import atvImage from '../images/icons/object_icons/cars/atv.jpg';
import motorbikeImage from '../images/icons/object_icons/cars/motorbike.jpg';
import scooterImage from '../images/icons/object_icons/cars/scooter.jpg';
import bicycleImage from '../images/icons/object_icons/cars/bicycle.jpg';
import planeImage from '../images/icons/object_icons/cars/plane.jpg';
// CARS

// DOORS
import garageDoorx2 from '../3DModels/doors/Garage-door-x2.gltf';
import garageDoorx3 from '../3DModels/doors/Garage-door-x3.gltf';
import garageDoorx4 from '../3DModels/doors/Garage-door-x4.gltf';
import door1 from '../3DModels/doors/Door-classic.gltf';
import door2 from '../3DModels/doors/Door-detail.gltf';
import door3 from '../3DModels/doors/Door-glass.gltf';
import door1Image from '../images/icons/object_icons/doors/D-classic.jpg';
import door2Image from '../images/icons/object_icons/doors/D-detail.jpg';
import door3Image from '../images/icons/object_icons/doors/D-glass.jpg';
import garageDoor1Image from '../images/icons/object_icons/doors/garage-x2.jpg';
import garageDoor2Image from '../images/icons/object_icons/doors/garage-x3.jpg';
import garageDoor3Image from '../images/icons/object_icons/doors/garage-x4.jpg';
// DOORS

// WINDOWS
import window1 from '../3DModels/windows/Window-small.gltf';
import window2 from '../3DModels/windows/Window-middle.gltf';
import window3 from '../3DModels/windows/Window-big.gltf';
import window4 from '../3DModels/windows/Window-half.gltf';
import window5 from '../3DModels/windows/Window-dublehalf.gltf';
import window6 from '../3DModels/windows/Window-horisontal.gltf';
import window1Image from '../images/icons/object_icons/windows/w-small.jpg';
import window2Image from '../images/icons/object_icons/windows/w-middle.jpg';
import window3Image from '../images/icons/object_icons/windows/w-big.jpg';
import window4Image from '../images/icons/object_icons/windows/w-half.jpg';
import window5Image from '../images/icons/object_icons/windows/w-dublehalf.jpg';
import window6Image from '../images/icons/object_icons/windows/w-horisontal.jpg';
// WINDOWS

// CABINETS
import cabinet1 from '../3DModels/cabinets/down-drawer.gltf';
import cabinet2 from '../3DModels/cabinets/down-double-small.gltf';
import cabinet3 from '../3DModels/cabinets/down-double.gltf';
import cabinet4 from '../3DModels/cabinets/move-double.gltf';
import cabinet5 from '../3DModels/cabinets/down-empty-chair.gltf';
import cabinet6 from '../3DModels/cabinets/tall-storage.gltf';
import cabinet7 from '../3DModels/cabinets/upper-classic-left.gltf';
import cabinet8 from '../3DModels/cabinets/upper-classic-right.gltf';
import cabinet9 from '../3DModels/cabinets/upper-double.gltf';
import cabinet10 from '../3DModels/cabinets/upper-short-left.gltf';
import cabinet11 from '../3DModels/cabinets/upper-short-right.gltf';
import cabinet12 from '../3DModels/cabinets/upper-single-half.gltf';
import cabinetImage1 from '../images/icons/object_icons/cabinets/down-drawer.jpg';
import cabinetImage2 from '../images/icons/object_icons/cabinets/down-double-small.jpg';
import cabinetImage3 from '../images/icons/object_icons/cabinets/down-double.jpg';
import cabinetImage4 from '../images/icons/object_icons/cabinets/move-double.jpg';
import cabinetImage5 from '../images/icons/object_icons/cabinets/down-empty-chair.jpg';
import cabinetImage6 from '../images/icons/object_icons/cabinets/tall-storage.jpg';
import cabinetImage7 from '../images/icons/object_icons/cabinets/upper-left.jpg';
import cabinetImage8 from '../images/icons/object_icons/cabinets/upper-right.jpg';
import cabinetImage9 from '../images/icons/object_icons/cabinets/upper-double.jpg';
import cabinetImage10 from '../images/icons/object_icons/cabinets/upper-short-left.jpg';
import cabinetImage11 from '../images/icons/object_icons/cabinets/upper-short-right.jpg';
import cabinetImage12 from '../images/icons/object_icons/cabinets/upper-single-half.jpg';
// CABINETS

// OTHER
import other1 from '../3DModels/other/bicycle.gltf';
import other2 from '../3DModels/other/treadmill.gltf';
import other3 from '../3DModels/other/universal-bench.gltf';
import other4 from '../3DModels/other/fridge-big.gltf';
import other5 from '../3DModels/other/fridge-small.gltf';
import other6 from '../3DModels/other/tv-stand.gltf';
import other7 from '../3DModels/other/tv-wall.gltf';
import otherImage1 from '../images/icons/object_icons/other/bicycle.jpg';
import otherImage2 from '../images/icons/object_icons/other/treadmill.jpg';
import otherImage3 from '../images/icons/object_icons/other/universal-bench.jpg';
import otherImage4 from '../images/icons/object_icons/other/fridge-big.jpg';
import otherImage5 from '../images/icons/object_icons/other/fridge-small.jpg';
import otherImage6 from '../images/icons/object_icons/other/tv-stand.jpg';
import otherImage7 from '../images/icons/object_icons/other/tv-wall.jpg';
// OTHER

const localization = new Localization();

export const addObjectsModels = [
  {
    title: localization.strings.cars,
    icon: carSvg,
    selection:
      [
        { image: mustangImage, model: mustangModel, wallObject: false, bottom_colision: false, top_colision: false },
        { image: carliftMustangImage, model: carLiftAndCar, wallObject: false, bottom_colision: false, top_colision: false },
        { image: carliftImage, model: carLift, wallObject: false, bottom_colision: false, top_colision: false },
        { image: mercedesImage, model: mercedes, wallObject: false, bottom_colision: false, top_colision: false },
        { image: suvImage, model: suv, wallObject: false, bottom_colision: false, top_colision: false },
        { image: pickupImage, model: pickup, wallObject: false, bottom_colision: false, top_colision: false },
        { image: atvImage, model: atv, wallObject: false, bottom_colision: false, top_colision: false },
        { image: motorbikeImage, model: motorbike, wallObject: false, bottom_colision: false, top_colision: false },
        { image: scooterImage, model: scooter, wallObject: false, bottom_colision: false, top_colision: false },
        { image: bicycleImage, model: bicycle, wallObject: false, bottom_colision: false, top_colision: false },
        { image: planeImage, model: plane, wallObject: false, bottom_colision: false, top_colision: false }
      ]
  },
  {
    title: localization.strings.cabinets,
    icon: cabinetSvg,
    selection: [
      { image: cabinetImage1, model: cabinet1, wallObject: true, bottom_colision: true, top_colision: false },
      { image: cabinetImage2, model: cabinet2, wallObject: true, bottom_colision: true, top_colision: false },
      { image: cabinetImage3, model: cabinet3, wallObject: true, bottom_colision: true, top_colision: false },
      { image: cabinetImage4, model: cabinet4, wallObject: false, bottom_colision: false, top_colision: false },
      { image: cabinetImage5, model: cabinet5, wallObject: true, bottom_colision: true, top_colision: false },
      { image: cabinetImage6, model: cabinet6, wallObject: true, bottom_colision: true, top_colision: true },
      { image: cabinetImage7, model: cabinet7, wallObject: true, bottom_colision: false, top_colision: true },
      { image: cabinetImage8, model: cabinet8, wallObject: true, bottom_colision: false, top_colision: true },
      { image: cabinetImage9, model: cabinet9, wallObject: true, bottom_colision: false, top_colision: true },
      { image: cabinetImage10, model: cabinet10, wallObject: true, bottom_colision: false, top_colision: true },
      { image: cabinetImage11, model: cabinet11, wallObject: true, bottom_colision: false, top_colision: true },
      { image: cabinetImage12, model: cabinet12, wallObject: true, bottom_colision: false, top_colision: true }
    ]
  },
  {
    title: localization.strings.other,
    icon: otherSvg,
    selection: [
      { image: otherImage1, model: other1, wallObject: false, bottom_colision: false, top_colision: false },
      { image: otherImage2, model: other2, wallObject: false, bottom_colision: false, top_colision: false },
      { image: otherImage3, model: other3, wallObject: false, bottom_colision: false, top_colision: false },
      { image: otherImage4, model: other4, wallObject: true, bottom_colision: true, top_colision: true },
      { image: otherImage5, model: other5, wallObject: true, bottom_colision: true, top_colision: true },
      { image: otherImage6, model: other6, wallObject: true, bottom_colision: true, top_colision: false },
      { image: otherImage7, model: other7, wallObject: true, bottom_colision: false, top_colision: true }
    ]
  }
];

export const wallObjectsModels = [
  {
    title: localization.strings.doors,
    icon: doorSvg,
    selection:
      [
        { image: door1Image, model: door1, wallObject: true, bottom_colision: true, top_colision: true },
        { image: door2Image, model: door2, wallObject: true, bottom_colision: true, top_colision: true },
        { image: door3Image, model: door3, wallObject: true, bottom_colision: true, top_colision: true },
        { image: garageDoor1Image, model: garageDoorx2, wallObject: true, bottom_colision: true, top_colision: true },
        { image: garageDoor2Image, model: garageDoorx3, wallObject: true, bottom_colision: true, top_colision: true },
        { image: garageDoor3Image, model: garageDoorx4, wallObject: true, bottom_colision: true, top_colision: true }
      ]
  },
  {
    title: localization.strings.windows,
    icon: windowSvg,
    selection:
      [
        { image: window1Image, model: window1, wallObject: true, bottom_colision: false, top_colision: true },
        { image: window2Image, model: window2, wallObject: true, bottom_colision: false, top_colision: true },
        { image: window3Image, model: window3, wallObject: true, bottom_colision: false, top_colision: true },
        { image: window4Image, model: window4, wallObject: true, bottom_colision: false, top_colision: true },
        { image: window5Image, model: window5, wallObject: true, bottom_colision: false, top_colision: true },
        { image: window6Image, model: window6, wallObject: true, bottom_colision: false, top_colision: true }
      ]
  }
];
