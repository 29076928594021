import { EdgePiece } from './EdgePiece';
import { Color, MeshBasicMaterial, TextureLoader, Float32BufferAttribute, Mesh, BufferGeometry, CircleGeometry } from 'three';
import cornerTextureHome from '../../assets/textures/edges/Home-Edge-Corner.jpg';
import cornerTexturePro from '../../assets/textures/edges/PRO-Edge-Corner.jpg';
export class Corner extends EdgePiece {
  static textureCornerPro = new TextureLoader().load(cornerTexturePro);
  static textureCornerHome = new TextureLoader().load(cornerTextureHome);
  static textureCorner = Corner.textureCornerPro;
  static selectedbaseColor = EdgePiece.defaultEdgeColor;
  static materialCorner = new MeshBasicMaterial({ map: Corner.textureCorner, color: Corner.selectedbaseColor });
  static changedCornerColors = new Set();

  constructor (index, edgeSize, tileSize, orientationRow = true, positiveDirection = true) {
    super();
    this.name = 'Corner';
    this.tileIndex = index;
    this.positiveDirection = positiveDirection;
    this.orientationRow = orientationRow;

    const geometryCorner = new CircleGeometry(edgeSize, 8, 1.5 * Math.PI, 0.5 * Math.PI);
    this.corner = new Mesh(geometryCorner, Corner.materialCorner);
    this.corner.tileIndex = index;
    this.corner.rotation.x = -Math.PI / 2;

    const cornerGeomExtraPart = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          0, 0, edgeSize,
          tileSize, 0, tileSize,
          tileSize, 0, edgeSize,

          0, 0, edgeSize,
          0, 0, tileSize,
          tileSize, 0, tileSize,

          edgeSize, 0, 0,
          tileSize, 0, edgeSize,
          tileSize, 0, 0,

          edgeSize, 0, 0,
          edgeSize, 0, edgeSize,
          tileSize, 0, edgeSize
        ],
        3
      )
    );

    const extraPart = new Mesh(cornerGeomExtraPart, EdgePiece.materialEdgeExtraPart);
    extraPart.rotation.x = Math.PI / 2;

    this.corner.add(extraPart);

    if (orientationRow) {
      if (!positiveDirection) {
        this.corner.rotation.z = -Math.PI / 2;
        this.corner.position.setX(tileSize);
      }
    } else {
      if (positiveDirection) {
        this.corner.rotation.z = Math.PI / 2;
        this.corner.position.setZ(tileSize);
      } else {
        this.corner.rotation.z = Math.PI;
        this.corner.position.setZ(tileSize);
        this.corner.position.setX(tileSize);
      }
    }

    this.add(this.corner);
  }

  changeColor = (newColor) => {
    this.newColor = newColor;
    this.corner.material = new MeshBasicMaterial({ map: Corner.textureCorner, color: new Color(newColor) });
    Corner.changedCornerColors.add(this);
  }

  static changeAllCornerColors = (color) => {
    Corner.selectedbaseColor = color;
    Corner.materialCorner.color = new Color(color);
    Corner.changedCornerColors.forEach(edgePiece => {
      edgePiece.corner.material = Corner.materialCorner;
    });
    Corner.changedCornerColors.clear();
  }

  static changeBaseTexture = (textureType) => {
    Corner.textureCorner = textureType === 'pro' ? Corner.textureCornerPro : Corner.textureCornerHome;
    Corner.materialCorner.map = Corner.textureCorner;
  }
}
