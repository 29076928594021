import { createElement } from '../assets/helpers/common';

export class Loader {
  constructor () {
    this.container = createElement('div', null, 'wholePageloaderContainer');
    // this.container.innerHTML = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';
    this.container.innerHTML = '<div class="loadingio-spinner"><div class="ldio"><div></div><div></div><div></div><div></div></div></div>';
    document.body.appendChild(this.container);
    this.count = 0;
  }

  hide = () => {
    this.count--;
    if (this.count === 0) {
      this.container.style.visibility = 'hidden';
      this.container.style.backgroundColor = '#ffffff77';
    }
  }

  show = () => {
    this.count++;
    this.container.style.visibility = 'visible';
  }
}
