
export function handleDisplayDrawer () {
  const drawer = document.getElementById('lp_drawer');
  drawer.classList.contains('lp_drawer_show')
    ? hideDrawer()
    : showDrawer();
}

export const handleOutsideClickDrawer = (e) => {
  !e.target.closest('#lp_drawer') &&
    hideDrawer();
};

export function showDrawer () {
  document.body.style.overflow = 'hidden';
  const drawer = document.getElementById('lp_drawer');
  drawer.classList.remove('lp_drawer_init');
  drawer.classList.remove('lp_drawer_close');
  drawer.classList.add('lp_drawer_show');
  const drawerWrapper = document.getElementById('lp_drawer_wrapper');
  drawerWrapper.classList.add('lp_drawer_wrapper_show');
  drawerWrapper.classList.remove('lp_drawer_wrapper_close');
  const buttons = document.getElementById('lp_content_buttons');
  buttons?.classList.remove('lp_content_buttons_show');
}

export function hideDrawer () {
  document.body.removeAttribute('style');
  const drawer = document.getElementById('lp_drawer');
  drawer.classList.remove('lp_drawer_show');
  drawer.classList.add('lp_drawer_close');
  const drawerWrapper = document.getElementById('lp_drawer_wrapper');
  drawerWrapper.classList.remove('lp_drawer_wrapper_show');
  drawerWrapper.classList.add('lp_drawer_wrapper_close');
  const buttons = document.getElementById('lp_content_buttons');
  buttons?.classList.add('lp_content_buttons_show');
}
