import { getCookie } from '../assets/helpers/cookie.js';
import { handleDisplayDrawer } from './drawer.js';

export function hideHeader () {
  document.getElementById('header').style.display = 'none';
}

export function showHeader () {
  document.getElementById('header').style.display = 'flex';
  handleShowHeaderButton();
}

export function handleShowHeaderButton () {
  const user = JSON.parse(window.localStorage.getItem('user'));
  const firstName = user && user.first_name;

  if (firstName !== null && getCookie('token_check') === 'valid') {
    showName(firstName);
  } else {
    showAuthButton();
  }
}

function showName (firstName) {
  const headerUserContainer = document.getElementById('header_user_container');
  headerUserContainer.style.display = 'flex';
  headerUserContainer.addEventListener('click', handleDisplayDrawer);

  document.getElementById('header_user').innerHTML = `Hi, ${firstName}`;
  const authButton = document.getElementById('header_auth');
  authButton && (authButton.style.display = 'none');
}

function showAuthButton () {
  const authButton = document.getElementById('header_auth');
  authButton.style.display = 'flex';
  authButton.addEventListener('click', handleDisplayDrawer);

  const headerUserContainer = document.getElementById('header_user_container');
  headerUserContainer && (headerUserContainer.style.display = 'none');
}
